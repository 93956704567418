<template>
  <div>
    <el-form-item :label="labelText(zdItem.label)">
      <el-date-picker
      style="float: left;"
      format="yyyy-MM-dd"
      value-format="yyyy-MM-dd"
      :disabled="zdItem.disabled"
      type="date"
      :placeholder="$t('view.data_placeholder')"
      v-model="zdItem.value"
      @change="dateChange"
      >
    </el-date-picker>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDDate", 
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
    };
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
  },
  created() {

    // console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    dateChange(val){
      console.info('val:',val)
      this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: val,
        });
    }
  },
};
</script>

<template>
  <div>
    <el-form-item :label="labelText(zdItem.label)">
      <el-row v-for="(item, index) in zdFormattedValue"
      :key="item.key">
        <el-col :span="4.5">
          <el-input placeholder="请输入内容" v-model="item.fields">
            <template slot="prepend">Fields</template>
          </el-input>
        </el-col>
        <el-col :span="4.5" :offset="1">
          <el-input
            placeholder="请输入内容"
            v-model="item.specification"
          >
            <template slot="prepend">Specification</template>
          </el-input>
        </el-col>
        <el-col :span="4.5" :offset="1">
          <el-input placeholder="请输入内容" v-model="item.fields">
            <template slot="prepend">Result</template>
          </el-input>
        </el-col>
        <el-col :span="4.5" :offset="1">
          <el-input placeholder="请输入内容" v-model="item.test_method">
            <template slot="prepend">Result</template>
          </el-input>
        </el-col>
        <el-col :span="3">
          <el-button
            type="primary"
            icon="el-icon-circle-plus"
            circle
            :disabled="coaAuthDisableSetup()"
            @click="addClick(item)"
          ></el-button>
          <el-button
            type="danger"
            :disabled="coaAuthDisableSetup()"
            icon="el-icon-delete"
            circle
            @click="removeClick(index)"
          ></el-button>
        </el-col>
      </el-row>
    </el-form-item>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "ZDCheckBox",
  props: {
    zdId: {
      type: String,
      required: true,
    }
  },
  computed: {
    // 从 Vuex 获取当前组件的整个状态
    ...mapGetters("zdInput", {
      zdGetComponentState: "getComponentState",
    }),
    // 获取当前组件的状态对象
    zdComponentState() {
      return this.zdGetComponentState(this.zdId);
    },
    // 从状态对象中获取各个字段
    zdItem() {
      return this.zdComponentState;
    },
    labelText() {
      return (label) => {
        let key = "content." + label;
        key = key.toLowerCase().replaceAll(" ", "_");
        return this.$t(key);
      };
    },
    zdFormattedValue: {
      get() {
        return this.zdItem.value || ""; // 获取输入框的值
      },
      set(value) {
        this.zdUpdateComponent({
          id: this.zdId,
          field: "value",
          value: value,
        }); // 更新输入框的值
      },
    },
  },
  created() {
    console.log("created:", this.zdItem);
  },
  methods: {
    // 映射 Vuex 的动作
    ...mapActions("zdInput", ["updateComponent"]),
    coaAuthDisableSetup(){
      let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
      if(userInfo.roleId == 3){
        return true;
      }
      return this.zdItem.disable;
    },
    zdUpdateComponent(payload) {
      this.updateComponent(payload); // 更新组件状态
    },
    handleChange() {
      this.zdUpdateComponent({
        id: this.zdId,
        field: "value",
        value: this.zdItem.value,
      });
    },
    addClick() {
      this.zdFormattedValue.push({});
    },
    removeClick(index) {
      if (this.zdFormattedValue.length > 1) {
        this.zdFormattedValue.splice(index, 1);
      }
    },
  },
};
</script>
